<template>
  <section>
    <b-row>
      <b-col>
        <switch-centro @centro-change="centroChange" :dataUrl="centrosNames"></switch-centro>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="stockJaulasChart00">
        </bruna-element-loader>
      </b-col>
      <b-col>
        <bruna-element-loader :dataUrl="stockJaulasChart01">
        </bruna-element-loader>
      </b-col>
      <b-col>
        <bruna-element-loader :dataUrl="stockJaulasChart02">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <ag-grid-table
          ref="grid-historico-cages"
          :configUrl="historicoCagesConfig"
          :dataUrl="historicoCagesData"
          :editUrl="historicoCagesEdit"
        ></ag-grid-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col> </b-col>
    </b-row>
  </section>
</template>

<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import SwitchCentro from './SwitchCentro.vue';

var qs = require('qs');

import
{
  BRow,
  BCol,
  BFormSelect,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover,   
  BFormCheckboxGroup
} from "bootstrap-vue";


import useApiServices from '@/services/useApiServices.js';


export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,  
    }
  },

  data()
  {


    return {


      historicoCagesConfig: useApiServices.historicoCagesConfig,
      historicoCagesData: useApiServices.historicoCagesData,
      historicoCagesEdit: useApiServices.historicoCagesEdit,

      stockJaulasChart00: useApiServices.stockJaulasChart00 + "?centro=all",
      stockJaulasChart01: useApiServices.stockJaulasChart01 + "?centro=all",
      stockJaulasChart02: useApiServices.stockJaulasChart02 + "?centro=all",

      centrosNames: useApiServices.stockJaulasCentros + "?centro=all",


    };
  },
  components: {

    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    BFormRadioGroup,
    BrunaElementLoader,
    BAvatar,
    AgGridTable,
    SwitchCentro,
    BFormCheckboxGroup

  },

  created()
  {




  },

  mounted() 
  {

          




  },


  methods: {

    centroChange(checked)
    {
      let api = this.$refs['grid-historico-cages'].gridApi

      if(api)
      {
        // get filter instance
        const countryFilterComponent = api.getFilterInstance('meta_data.centro'); 

        if(countryFilterComponent)
        {
          // get filter model
          //const model = countryFilterComponent.getModel(); 

          // set filter model and update
          countryFilterComponent.setModel({ values: checked });

          // refresh rows based on the filter (not automatic to allow for batching multiple filters)
          api.onFilterChanged();

        }


      }





      let query = qs.stringify({ centros: checked })

      //console.log({ checked, query })

      this.stockJaulasChart00 = useApiServices.stockJaulasChart00 + "?" + query
      this.stockJaulasChart01 = useApiServices.stockJaulasChart01 + "?" + query
      this.stockJaulasChart02 = useApiServices.stockJaulasChart02 + "?" + query

    },





  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
